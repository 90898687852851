import React from "react"
import FeaturesBillingSoftware from "./FeaturesBillingSoftware"
import PlansBillingSoftware from "./PlansBillingSoftware"
import desktopbring from "../../img/vendors/epos/epos-machine-cash-tray.png"

const EposPage = () => {
  return (
    <>
      <section className="cloud-server-header billing-software-gradient">
        <div className="container-xl">
          <div className="row">
            <div className="col-12 text-center">
              <h1 className="page-title text-white">
                Grow your retail business with powerful EPOS Software
              </h1>
              <p className="text-white pb-3 description">
                Next-level Simplified Billing Software Management.
              </p>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-12 col-lg-7 mt-2 mt-lg-0">
              <ul className="text-start text-white list-unstyled mb-0">
                <li className="d-flex">
                  <div className="ms-4">
                    <h2 className="title mb-2">
                      Keep Your Store in Your Control
                    </h2>
                    <p className="text-white pb-3 description">
                      Our Billing software that helps you keep a control on your
                      store with smart billing, reporting and inventory
                      management features. Barcode, Touch Screen, Smart
                      purchase, Offers and more other features helps you grow
                      your profits and scale.
                    </p>
                  </div>
                </li>

                <li className="d-flex">
                  <div className="ms-4">
                    <h2 className="title mb-2">Failover</h2>
                    <p className="text-white pb-0 description">
                      Automatic daily backups, If you detect a hardware issue,
                      we move your data to another hardware, ensuring that your
                      data is always available and you never lose.
                    </p>
                  </div>
                </li>
                <li className="d-flex">
                  <div className="ms-4">
                    <h2 className="title mb-2">Instant Support</h2>
                    <p className="text-white pb-3 description">
                      No need your customers wait for queue due to software
                      issues, Instant teamweaver, phone and Chat support.
                    </p>
                  </div>
                </li>
              </ul>
            </div>
            <div className="d-none d-lg-block col-lg-5">
              <div className="text-center ms-0 ms-xl-5">
                <img
                  className="img-fluid header-banner"
                  alt="epos machine"
                  src={desktopbring}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="pricing billing-price">
        <div className="container-xl">
          <h2 className="component-title mb-2">
            EPOS Billing Software in India
          </h2>

          <div className="row win-pricing justify-content-center">
            <PlansBillingSoftware />
          </div>
        </div>
      </section>
      <section className="win-features billing-features">
        <h3 className="component-title text-center">
          What Does EPOS Billing Software mean for your Business?
        </h3>
        <div className="container-xl">
          <div className="row feature-wrapper">
            <FeaturesBillingSoftware />
          </div>
        </div>
      </section>
    </>
  )
}

export default EposPage
