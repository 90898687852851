import React from "react"
import Layout from "../components/layout"
import BillingSoftware from "../components/home/index"
import Seo from "../components/seo"
const IndexPage = () => {
  return (
    <Layout>
      <Seo
        title="Billing Software, EPOS Software in Chennai, India"
        desc="Motherepos offers EPOS Software at competitive prices."
        keywords="Billing Software, EPOS Software, pos software"
      />
      <BillingSoftware />
    </Layout>
  )
}

export default IndexPage
