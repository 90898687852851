import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const FeaturesBillingSoftware = () => {
  return (
    <>
      <div className="col-12 col-lg-6">
        <div className="feature-block">
          <h4 className="title">Advanced Reports</h4>
          <div className="description">
            Mobile app reporting and stock management. Customize your sales
            reports to easily see how your stores are performing, what products
            are making you the most money, and discover who your top sales
            people are.
          </div>
          <StaticImage
            src="../../img/svg/analytics.svg"
            alt="Advanced Reports"
            width={64}
            height={64}
          />
        </div>
      </div>

      <div className="col-12 col-lg-6">
        <div className="feature-block">
          <h4 className="title">CCTV Integration</h4>
          <div className="description">
            {" "}
            EPOS can be integrated with CCTV, Live Remote monitoring, Using CCTV
            Advanced POS Integration software, connecting your CCTV system with
            your POS or access control system is easier, more reliable, and cost
            effective. Preventing Loss Due to Internal Theft and Fraud
          </div>
          <StaticImage
            src="../../img/svg/cctv.svg"
            alt="CCTV Integration"
            width={64}
            height={64}
          />
        </div>
      </div>
      <div className="col-12 col-lg-6">
        <div className="feature-block">
          <h4 className="title">Advanced Features</h4>
          <div className="description">
            {" "}
            Advanced staff control, Remote multi shop Backoffice, Touch screen
            support, Label printing & designer, Caller id can be integrated,
            Second printer option (for Hotel), 2-line led display can be
            integrated, Customer display option (additional screen).
          </div>
          <StaticImage
            src="../../img/svg/feature.svg"
            alt="billing software features"
            width={64}
            height={64}
          />
        </div>
      </div>
    </>
  )
}

export default FeaturesBillingSoftware
